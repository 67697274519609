<template>
  <v-container fluid> 
    <v-layout row-wrap align-center>
      <v-flex xs12 class="text-xs-left page-toolbar">
        <h1 class="d-inline-block" style="font-size: 25px;">Device Management &gt; Load Balancing</h1>
      </v-flex>
    </v-layout>
    <v-layout 
      :style="{ 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }"
    >
      <v-flex
        xs12
        :sm6="menu === null"
        :md5="[null, 'summary-confirmation'].includes(menu)"
        :md6="menu === 'configure-devices'"
      >
        <v-card style="justify-content: center" class="mt-5 mb-5">
          <v-card-title v-if="menu !== 'group-summary'" class="flex">
            <LoadBalancingFormTitle
              :menu="menu"
              :is-edit="!!editId"
            />
          </v-card-title>

          <v-card-text v-if="!!loading">
            <v-flex xs12 class="d-flex justify-center">
              <v-progress-circular class="loading-circle center-margin" size="20" indeterminate color="orange" />
            </v-flex>
          </v-card-text>
          <CreateGroupForm
            v-else-if="menu === null"
            :name="inputs.name"
            :amperage="inputs.amperage"
            @input:name="inputs.name = $event"
            @input:amperage="inputs.amperage = $event"
            @submit="next"
            @back="goBack"
          />
          <SelectDevicesForm
            v-else-if="menu === 'select-devices'"
            :loading="loading"
            :selected-devices="selectedDevices"
            :name="inputs.name"
            @input:selectedDevices="selectedDevices = $event"
            @submit="next"
            @back="goBack"
          />
          <ConfigureDevicesForm
            v-else-if="menu === 'configure-devices'"
            :devices="selectedDevices"
            :min-amperages="inputs.minAmperages"
            :max-amperages="inputs.maxAmperages"
            :always-ons="inputs.alwaysOns"
            :ratios="inputs.ratios"
            :breaker-amperage="inputs.amperage" 
            :start-from-last="previousMenu === 'summary-confirmation'"
            @input:minAmperages="inputs.minAmperages = $event"
            @input:maxAmperages="inputs.maxAmperages = $event"
            @input:alwaysOns="inputs.alwaysOns = $event"
            @input:ratios="inputs.ratios = $event"
            @back="goBack"
            @submit="next"
          />
          <Summary
            v-else-if="menu === 'summary-confirmation'"
            :selected-devices="selectedDevices"
            :inputs="inputs"
            @back="goBack"
            @submit="submit"
          />
        </v-card>
      </v-flex>
    </v-layout>
    <ExitConfirmationModal
      :show="leaving.show_confirmation"
      :text='`You are currently in the process of creating or editing a load balancing group. 
              Navigating away from this page will cause you to lose any changes you have made.<br /><br />
              Please go back and complete the creation/edit process if you would like to keep your changes.`'
      @confirmed="leaving.confirmed = true;$router.push(leaving.to);"
      @cancel="leaving.confirmed = false; leaving.to = null; leaving.show_confirmation = false;"
      @changed="(v) => leaving.show_confirmation = v"
    />
  </v-container>
</template>

<script>
import Api from "@/library/apis/Api";
import ExitConfirmationModal from "@/components/modals/ExitConfirmationModal.vue";
import { mapState } from "vuex";
import LoadBalancingFormTitle from '@/components/loadBalancing/LoadBalancingFormTitle.vue';
import CreateGroupForm from '@/components/loadBalancing/forms/CreateGroup.vue';
import SelectDevicesForm from "@/components/loadBalancing/forms/SelectDevices.vue";
import ConfigureDevicesForm from "@/components/loadBalancing/forms/ConfigureDevices.vue";
import Summary from "@/components/loadBalancing/Summary.vue";
import { pick } from 'lodash-es';
import texts from "@/components/loadBalancing/texts";
import { DeviceHelpers } from "@/library/helpers";

const defaultInputs = () => ({
  name: null,
  amperage: null,
  default: null,
  vote: null,
  minAmperages: {},
  maxAmperages: {},
  ratios: {},
  alwaysOns: {},
})

export default {
  name: "LoadBalancingForm",
  components: {
    ExitConfirmationModal,
    LoadBalancingFormTitle,
    CreateGroupForm,
    SelectDevicesForm,
    ConfigureDevicesForm,
    Summary
  }, 
  data() {
    return {
      menu: null, 
      previousMenu: null,
      tooltipContent: texts.ratio,
      collectives: [],
      error: null,
      inputs: defaultInputs(),
      leaving: {
        confirmed: false,
        show_confirmation: false,
        to: null,
      },
      selectedDevices: []
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      role: (state) => state.Auth.role,
      outletHighlight: (state) => state.outletHighlight,
      adminActiveView: (state) => state.Admin.activeView,
      managerview: (state) => state.Admin.managerview,
      addPlugToCollective: (state) => state.LoadBalancing.addPlugToCollective,
    }),
    editId() {
      return this.$route.params?.id;
    }
  },
  methods: {
    async submit() {
      try {
        this.$store.dispatch("loading", true);

        const isEdit = !!this.editId

        const apiParams = {
          name: this.inputs.name, 
          amperage: this.inputs.amperage * DeviceHelpers.STABLE_AMPERAGE_CONVERTER
        }; 
          
        if (this.role === "admin") apiParams.ownerId = this.managerview.id; 

        if (!isEdit) {
          const createResponse = await Api.loadBalancingCollectiveCreate(this.role, apiParams);
          if (!createResponse || createResponse.error) {
            this.$store.dispatch("snackbar", {
              message: "Failed to Create Load Balancing Group",
            });
            return;
          }
        }
        
        const payload = isEdit ? { collectiveId: this.editId } : { collectiveName: this.inputs.name,  ownerId: this.role === "admin" ? this.managerview.id : undefined }
        const collectives = await Api.loadBalancingCollectiveGet(this.role, payload);
        const [collective] = collectives || []
        if (!collective) {
          this.$store.dispatch("snackbar", {
            message: "Failed to Fetch Load Balancing Groups",
          });
          return;
        }

        if (isEdit) {
          await Promise.allSettled(collectives.filter(c => !!c.plug_identifier).map(c => {
            return Api.loadBalancingCollectiveRemovePlug(this.role, {
              plugIdentifier: c.plug_identifier,
            });
          }));
          await Api.loadBalancingCollectiveUpdate(this.role, {
            collectiveId: collective.the_collective_id,
            ...apiParams,
          });
        }

        await Promise.allSettled(this.selectedDevices.map(identifier => {
          return Api.loadBalancingCollectiveAddPlug(this.role, {
            collectiveId: collective.the_collective_id,
            plugIdentifier: identifier,
            defaultAmperage: this.inputs.alwaysOns[identifier],
            vote: this.inputs.ratios[identifier],
            minAmperage: this.inputs.minAmperages[identifier],
            maxAmperage: this.inputs.maxAmperages[identifier]
          });
        }));

        this.$store.commit("LoadBalancing/setState", { addPlugToCollective: null })
        this.$store.dispatch("snackbar", {
          message: `Successfully ${isEdit ? "edit" : "create"} Load Balancing Group`,
        });
      } catch (error) {
        this.$store.dispatch("snackbar", {
          message: "Failed to create or edit Load Balancing Group",
        });
      } finally {
        this.leaving.confirmed = true
        this.$store.dispatch("loading", false);
        this.$router.push(this.role === 'admin' ? '/admin/managerview-load-balancing' : '/owner/load-balancing');
      }

      this.next();
    },
    goBack() {
      this.previousMenu = this.menu;
      switch (this.menu) { 
        case null:
          this.$router.push({ name: this.role === 'admin' ? 'admin-managerview-load-balancing' : 'owner-load-balancing' });
          break; 
        case "select-devices":
          this.menu = null; 
          break; 
        case "configure-devices":
          this.inputs = {
            ...this.inputs,
            ...pick(defaultInputs, ['minAmperages', 'maxAmperages', 'ratios', 'alwaysOns'])
          }
          this.menu = "select-devices";
          break; 
        case "summary-confirmation":
          if (this.selectedDevices.length === 0) this.menu = "select-devices";
          else this.menu = "configure-devices";
          break;
        case "group-summary": 
          this.menu = "summary-confirmation";
          break; 
      }
    },
    next() { 
      this.previousMenu = this.menu;
      switch (this.menu) { 
        case null:
          this.menu = "select-devices";
          break; 
        case "select-devices":
          if (this.selectedDevices.length === 0) this.menu = "summary-confirmation";
          else this.menu = "configure-devices";
          break;
        case "configure-devices":
          this.menu = "summary-confirmation";
          break;
        case "summary-confirmation": 
          this.menu = "group-summary"; 
          break; 
        case "group-summary": 
          this.$router.push({ name: this.role === 'admin' ? 'admin-managerview-load-balancing' : 'owner-load-balancing' });
          break; 
      }
    }
  },
  async mounted() {
    if (this.role === "admin" && !this.managerview) {
      this.$router.push("/admin/managerview");
      return;
    }
    if (!this.addPlugToCollective && this.$route.query?.mode === "add-plug-to-collective") {
      this.$router.push(this.$route.path)
      return
    }
    this.$store.dispatch("LoadBalancing/get")
    if (this.editId) {
      this.$store.dispatch("loading", true);
      const collectives = await Api.loadBalancingCollectiveGet(this.role, { collectiveId: this.editId, ownerId: this.role === "admin" ? this.managerview?.id : undefined });
      if (!collectives || collectives.length === 0) {
        this.$store.dispatch("loading", false);
        this.$router.push(this.role === 'admin' ? '/admin/managerview-load-balancing' : '/owner/load-balancing');
      }

      const [collective] = collectives;
      this.inputs = {
        ...this.inputs,
        name: collective.name,
        amperage: (collective.amperage / DeviceHelpers.STABLE_AMPERAGE_CONVERTER)
      };

      if (this.addPlugToCollective && this.$route.query?.mode === "add-plug-to-collective") {
        collectives.push({
          plug_identifier: this.addPlugToCollective
        })
        this.$nextTick(() => {
          this.menu = "configure-devices";
        })
      } 

      for (const collective of collectives) {
        const plug_identifier = collective.plug_identifier;
        if (plug_identifier !== null) this.selectedDevices.push(plug_identifier) 

        this.inputs.minAmperages[plug_identifier] = collective.min_amperage;
        this.inputs.maxAmperages[plug_identifier] = collective.max_amperage;
        this.inputs.alwaysOns[plug_identifier] = collective.default_amperage;
        this.inputs.ratios[plug_identifier] = collective.vote;
      }
      this.$store.dispatch("loading", false);
    }
  },
  beforeRouteLeave: function(to, from, next) {
    if (this.leaving.confirmed) {
      next();
    } else {
      this.leaving.to = to.fullPath;
      this.leaving.show_confirmation = true;
      this.leaving.confirmed = false;
      next(false);
    }
  },
};
</script>

