<template>
  <v-container fluid h-100>
    <v-layout row wrap align-center>
      <v-flex xs3 sm6 class="text-xs-left page-toolbar">
        <h1 class="d-inline-block" style="font-size: 25px;">Device Management &gt; Load Balancing</h1>
      </v-flex>
      <v-flex xs9 sm6 class="text-xs-right page-toolbar">
        <v-btn 
          v-if="collectives.length > 0"
          small 
          dark 
          color="green" 
          class="plugzio-button create-button mx-0 px-3 mb-1"
          style="min-width: auto; height: 30%; border-radius: 30px;"
          round 
          outline 
          :disabled="!allowCreate"
          @click="$router.push({ name: role == 'admin' ? 'admin-managerview-load-balancing-form' : 'owner-load-balancing-form' })"
        >
          <v-icon size="20" class="ma-0 font-weight-bold" key="icon">add</v-icon>
          <span 
            class="ml-1 hidden-md-and-down" 
            key="description"
          >
            CREATE GROUP
          </span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout
      v-if="loading"
      align-center justify-center d-flex
      class="h-100 text-xs-center"
    >
      <v-flex v-if="loading" class="px-3">
        <v-progress-circular
          class="loading-circle center-margin"
          size="50"
          indeterminate
          color="orange"
        />
      </v-flex>
    </v-layout>
    <v-layout
      v-else-if="collectives.length === 0"
      align-center justify-center d-flex
      class="h-100 text-xs-center"
    >
      <Empty
        :data="loading"
      >
        <ZeroLoadBalancing  /> 
        <v-btn 
          small 
          dark 
          color="green" 
          class="plugzio-button create-button mx-0 pr-3 pl-2 mb-1 px-3" 
          style="min-width: auto; height: 30%; border-radius: 30px;"
          round 
          outline 
          :disabled="!allowCreate"
          @click="$router.push({ name: role == 'admin' ? 'admin-managerview-load-balancing-form' : 'owner-load-balancing-form' })"
        >
          <v-icon size="20" style="margin: 0; font-weight: 900;" key="icon">add</v-icon>
          <span class="ml-1" key="description">CREATE GROUP</span>
        </v-btn>
      </Empty>
    </v-layout>
    <LoadBalancingList v-else />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";;
import ZeroLoadBalancing from "@/components/zero/LoadBalancing.vue"; 
import Empty from "@/containers/Empty.vue";
import LoadBalancingList from "@/components/loadBalancing/List.vue";
import { OwnerProxyHelpers } from '@/library/helpers';

export default {
  name: "LoadBalancing",
  components: {
    ZeroLoadBalancing,
    Empty,
    LoadBalancingList,
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      managerview: (state) => state.Admin.managerview,
      adminActiveView: (state) => state.Admin.activeView,
      collectives: (state) => state.LoadBalancing.collectives,
      loading: (state) => state.loading
    }),
    ...mapGetters("Device", { 
      hiddenDevices: "hiddenDevices",
      visibleDevices: "visibleDevices"
    }),
    allowCreate() {
      return OwnerProxyHelpers.isHaveAccessToFeature("DEVICE_LOAD_BALANCING_CREATE")
    }
  },
  async mounted() {
    if (this.role === "admin" && !this.managerview) {
      this.$router.push("/admin/managerview");
      return;
    }
    this.$store.dispatch("LoadBalancing/get");
  }
};
</script>