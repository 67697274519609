<template>
  <v-container fluid :class="{ 'fill-height text-xs-center full-inner-height': empty || !withData }">
    <template v-if="withData === true">
      <v-layout row wrap>
        <v-flex xs5 sm3 class="text-xs-left page-toolbar">
          <h1 class="d-inline-block" style="font-size: 25px;">{{ role == "admin" && !!managerview ? `${managerview.owner_username} > ` : "" }} Device Management</h1>
        </v-flex>
        <v-flex xs7 sm9 class="text-xs-right page-toolbar">
          <MergedRegisterPlug
            class="ml-3 mt-3 d-inline-block"
            @load="loadPlugs()"
          />
          <ExportDeviceData
            :devices="showedPlugs"
            class="mx-3 mt-3 d-inline-block"
          />
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex md6 xs12>
          <TotalPlugsTile :plugs="showedPlugs" />
        </v-flex>
        <v-flex md6 xs12>
          <v-layout col wrap>
            <v-flex v-if="role === 'owner' || role === 'admin'" xs12>
              <UserAccessTile />
            </v-flex>
            <v-flex v-if="role === 'admin' || role === 'owner'" xs12>
              <ModeTile />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <OutletsTile :highlight="outletHighlight" :is-summary="false" @plugsChanged="(new_plugs) => (showedPlugs = new_plugs)" />
        </v-flex>
      </v-layout>
    </template>
    <v-layout align-center justify-center v-else>
      <Empty :data="withData" :width="750">
        <ZeroOutlets />
        <MergedRegisterPlug class="ml-3 d-inline-block" @load="loadPlugs()" always-show-button-text />
      </Empty>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Api from "@/library/apis/Api";
import OutletsTile from "@/components/OutletsTile";
import UserAccessTile from "@/components/UserAccessTile";
import ModeTile from "@/components/ModeTile";
import MergedRegisterPlug from "@/components/mergedRegisterPlug/MergedRegisterPlug";
import Empty from "@/containers/Empty";
import ZeroOutlets from "@/components/zero/Outlets";
import TotalPlugsTile from "@/components/graphs/TotalPlugsTile";
import ExportDeviceData from "@/components/modals/ExportDeviceData";

export default {
  components: {
    OutletsTile,
    UserAccessTile,
    ModeTile,
    Empty,
    ZeroOutlets,
    TotalPlugsTile,
    MergedRegisterPlug,
    ExportDeviceData,
  },
  data() {
    return {
      actionSub: false,
      withData: false,
      showedPlugs: [],
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      adminActiveView: (state) => state.Admin.activeView,
      managerview: (state) => state.Admin.managerview,
      userview: (state) => state.Admin.userview,
    }),
    ...mapGetters(["accessPlans"]),
    ...mapGetters("Admin", {
      adminViewAsUser: "viewAsUser",
    }),
    formData() {
      return this.managerview ? { ownerUsername: this.managerview.owner_username } : {} ;
    },
    roleApiPrefix() {
      return this.adminActiveView == "managerview" ? "admin/owner" : this.adminActiveView == "userview" ? "admin/user" : this.role;
    },
    empty() {
      return this.withData === false;
    },
    outletHighlight() {
      return this.$store.state.outletHighlight;
    }
  },
  methods: {
    loadPlugs() {
      this.$store.dispatch("loading", true);
      Promise.all([
        Api.plugs(this.roleApiPrefix, this.formData),
        Api.ongoingSessions(this.roleApiPrefix, this.formData)
      ])
        .then(([plugs, activeSessions]) => {
          this.$store.dispatch("setSessions", activeSessions || []);
          this.$store.dispatch("setPlugs", plugs || []);
          this.withData = !!plugs && plugs.length > 0;
          if (!!plugs && plugs.length > 0) this.$store.dispatch("setPlugClosestExpirationTimestamp", plugs);
        })
        .catch((error) => console.log(error))
        .finally(() => this.$store.dispatch("loading", false));
    }
  },
  mounted() {
    if (this.role == "admin" && !this.managerview) return this.$router.push("/admin/managerview");
    let formData = {}
    if (this.role === "admin") {
      if (!!this.managerview) formData = { ownerUsername: this.managerview.owner_username } 
      if (!!this.userview) formData = { username: this.adminViewAsUser.username }
    }
    Promise.all([
      Api.plugPaymentAccessPlans(this.roleApiPrefix, this.formData),
      Api.wallets(this.roleApiPrefix, formData)
    ])
      .then(([paymentPlans, wallets]) => {
        this.$store.dispatch("setPlans", paymentPlans);
        this.$store.dispatch("setWallets", wallets);
      })
      .catch((error) => console.log(error));

    this.loadPlugs();
    this.actionSub = this.$store.subscribeAction((action) => {
      if (action.type === "loadPlugs") this.$nextTick(() => this.loadPlugs());
    });
  },
  beforeDestroy() {
    this.$store.dispatch("outletHighlight", null);
    // this.actionSub();
  },
};
</script>

<style lang="sass">
.plug-lists-avatar
  position: relative
  height: 92px !important
  width: 92px !important
  margin-left: 6px
  @media only screen and (max-width: 560px)
    height: 64px !important
    width: 64px !important

.plug-lists-avatar
  .access-mode-icon
    position: absolute
    bottom: -24px
    right: -24px
    @media only screen and (max-width: 560px)
      bottom: -20px
      right: -20px

.users-header
  background: #f4f4f4

.users-title
  font-size: 12px
  color: gray

.users-list
  border-top: 1px solid #f2f2f2
  padding-right: 0

.users-list .v-list__tile
  padding-right: 12px

.users-list:first-child
  border: none !important

.user-name
  font-size: 14px !important

.user-access-plan
  font-size: 12px !important

.close
  border: none !important
  border-radius: 2px !important

.front-sample
  max-width: 340px
  margin: 0 auto
</style>
