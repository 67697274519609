<template>
  <v-container fluid class="fill-height text-xs-center full-inner-height">
    <v-layout row wrap justify-center>
      <v-flex xs12 class="mb-3">
        <h1 class="text-xs-left page-toolbar">{{ role === "admin" && !!managerview ? `${managerview.owner_username} > ` && menu === null : "" }} 
          Service Subscription &gt; Configure Device Subscriptions
          <span v-if="['reactivate', 'reactivate-currency-input', 'reactivate-time-input', 'reactivate-submit'].includes(menu)"> > Reactivate </span>
          <span v-if="['extend-subscription', 'extend-subscription-form', 'extend-subscription-summary'].includes(menu)"> > Extend Subscription </span>
          <span v-if="['set-auto-payment', 'set-auto-payment-wallet', 'set-auto-payment-confirmation'].includes(menu)"> > Set Auto Payment </span>
        </h1>
        <h2 
          v-if="
          [
            'extend-subscription-form', 'extend-subscription-summary',
            'reactivate-currency-input', 'reactivate-time-input',
            'set-auto-payment-wallet', 'set-auto-payment-confirmation'
          ].includes(menu)" 
          class="mt-5 mb-1 ml-3 text-xs-left"
        >
          Devices Selected
        </h2>
      </v-flex>
      <v-flex 
        :xs10="[
          null, 
          'reactivate-currency-input', 
          'reactivate-time-input', 
          'reactivate-submit', 
          'extend-subscription-form', 
          'extend-subscription-summary', 
          'set-auto-payment-wallet', 
          'set-auto-payment-confirmation'
        ].includes(menu)"
        :xs12="['reactivate', 'extend-subscription', 'set-auto-payment'].includes(menu)"
        :lg4="[null, 'reactivate-submit'].includes(menu)" 
        :sm6="[null, 'reactivate-submit'].includes(menu)"
        :lg12="![null, 'reactivate-submit'].includes(menu)" 
        :sm12="![null, 'reactivate-submit'].includes(menu)"
        class="align-center justify-center mt-2"
        style="align-self: flex-end; margin-top: 2px; margin-bottom: auto;"
      >
        <v-card>
          <v-card-title v-if="menu === null" class="flex">
            <h3>Configure Device Subscriptions</h3>
            <v-spacer /> 
          </v-card-title>
          <v-card-text>
            <v-layout xs12 row>
              <v-flex xs3 align-center d-flex v-if="menu === null">
                <strong class="ml-auto mt-5 mb-5">Action:</strong>
              </v-flex>
              <v-flex
                lg12
                sm12
                class="text-xs-center" 
                :class="{ 'mt-4': menu === null, 'mt-0': menu !== null }"
              >
                <v-select
                  v-if="menu === null"
                  v-model="inputs.options"
                  :items="['Extend Subscription', 'Set Auto Payment']"
                  label=""
                  outline
                  required
                  :rules="[rules.required]"
                  :disabled="loading || !allowConfigure"
                  hide-details
                  style="align-items: center; justify-content: center;"
                />
                <div class="ma-2"> 
                  <DeviceTable
                    class="device-table-component"
                    v-if="
                    [
                      'extend-subscription', 'reactivate', 'set-auto-payment', 'select',
                      'reactivate-currency-input', 'reactivate-time-input', 
                      'extend-subscription-form', 'extend-subscription-summary',
                      'set-auto-payment-wallet', 'set-auto-payment-confirmation'
                    ].includes(menu)"
                    :loadingCombinedPlugs="loadingCombinedPlugs"
                    :hideAutoPayment="['reactivate', 'extend-subscription', 'set-auto-payment', 'set-auto-payment-wallet', 'set-auto-payment-confirmation'].includes(menu)"
                    :allow-selection="
                                      ![
                                        'reactivate-currency-input', 'reactivate-time-input', 'reactivate-submit', 
                                        'extend-subscription-form', 'extend-subscription-summary', 
                                        'set-auto-payment-wallet', 'set-auto-payment-confirmation'
                                      ].includes(menu)"
                    :hideSelectAll="
                                      ![
                                        'reactivate-currency-input', 'reactivate-time-input', 'reactivate-submit', 
                                        'extend-subscription-form', 'extend-subscription-summary', 
                                        'set-auto-payment-wallet', 'set-auto-payment-confirmation'
                                      ].includes(menu)"
                    :selected="selectedDevices"
                    @selected="selectedDevices = $event" 
                    :dataplans="dataplans"
                    @dataplans="dataplans = $event"
                    :hideSearchBar="['reactivate', 'extend-subscription', 'set-auto-payment'].includes(menu)"
                    :hideFilter="['reactivate', 'extend-subscription', 'set-auto-payment'].includes(menu)"
                    :only-show-selected="
                                        [
                                          'select', 'reactivate-currency-input', 'reactivate-time-input', 
                                          'extend-subscription-form', 'extend-subscription-summary', 
                                          'set-auto-payment-wallet', 'set-auto-payment-confirmation'
                                        ].includes(menu)"
                    :disabledDevices="['reactivate'].includes(menu)"
                    :activeDevices="['extend-subscription'].includes(menu)"
                    :additionalTable="[
                                        'extend-subscription-form', 'extend-subscription-summary', 'set-auto-payment-wallet', 'set-auto-payment-confirmation'
                                      ].includes(menu)"
                    :additionalDataTable="
                                          [
                                            'extend-subscription-form', 'extend-subscription-summary', 'set-auto-payment-wallet', 'set-auto-payment-confirmation'
                                          ].includes(menu)"
                  />
                  <ReactivateDevices 
                    v-if="['reactivate-currency-input', 'reactivate-time-input'].includes(menu)"
                    :step="menu === 'reactivate-currency-input' ? 0 : 1"
                    :feedbackSent="menu === 'reactivate-submit'"
                    :inputCurrency="selectedCurrency"
                    :inputTime="parseInt(addTime)"
                    @update:inputCurrency="selectedCurrency = $event"
                    @update:inputTime="addTime = $event"
                    @cancel="backToReactivate"
                    @next="goToNextStepReactivate"
                    @submit="submitReactivate"
                  /> 
                  <SetAutoPayment 
                    v-if="['set-auto-payment-wallet', 'set-auto-payment-confirmation'].includes(menu)"
                    :stepAutoPayment="menu === 'set-auto-payment-wallet' ? 0 : 1"
                    :selectedPlugAuto="selectedDevices"
                    @selectedPlugAuto="selectedDevices = $event"
                    :dataplans="dataplans"
                    @dataplans="dataplans = $event" 
                    @setAutoNextButton="handleNextAutoDialog"
                    @setAutoBackButton="handleBackAutoDialog"
                    @setAutoCancelButton="handleCancelAutoDialog"
                    @confirmAutoPayment="autoPaymentConfirmation"
                  /> 
                  <ExtendSubscription 
                    v-if="['extend-subscription-form', 'extend-subscription-summary'].includes(menu)"
                    :extendStep="menu === 'extend-subscription-form' ? 0 : 1"
                    :selectedPlugExtend="selectedDevices"
                    @selectedPlugExtend="selectedDevices = $event"
                    :dataplans="dataplans"
                    @dataplans="dataplans = $event" 
                    @extendGoBack="handleExtendBackButton"
                    @extendCancel="handleExtendCancelButton"
                    @extendGoNext="extendToNextStep"
                    @extendConfirm="extendConfirmation"
                  />
                  <div
                    v-if="menu === 'reactivate-submit'"
                    class="reactivate-card text-xs-center py-2 ma-auto"
                  >
                    <h3 class="success--text">Request has been submitted</h3>
                    <p class="mt-2 mb-0">We will contact you by e-mail within 1-2 business days</p>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-btn 
              v-if="
                  ![
                    'reactivate-currency-input', 'reactivate-time-input', 'reactivate-submit', 
                    'extend-subscription-form', 'extend-subscription-summary',
                    'set-auto-payment-wallet','set-auto-payment-confirmation'
                  ].includes(menu)"
              color="gray" 
              flat 
              :disabled="loadingCombinedPlugs"
              @click="goBack()"
            >
              CANCEL
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn 
              v-if="
                ![
                  'reactivate-currency-input', 'reactivate-time-input', 'reactivate-submit', 
                  'extend-subscription-form', 'extend-subscription-summary', 'set-auto-payment-wallet', 
                  'set-auto-payment-confirmation'
                ].includes(menu)
              "
              color="green darken-1" 
              flat
              :disabled="
                inputs.options === null || loadingCombinedPlugs || 
                (['reactivate', 'extend-subscription', 'set-auto-payment'].includes(menu) && selectedDevices.length === 0) ||
                !allowConfigure
              "
              :loading="loading" 
              @click="goNext()"
            >
              NEXT
            </v-btn>
            <v-btn 
              v-if="menu === 'reactivate-submit'"
              color="green darken-1" 
              flat
              :loading="loading" 
              :disabled="!allowConfigure"
              @click="endReactivate()"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script> 
import validators from "@/mixins/validators";
import { mapState, mapGetters } from "vuex";
import Api from "./../../library/apis/Api";
import DeviceTable from "./ConfigureDeviceSubscription/components/DeviceTable.vue";
import ReactivateDevices from "./ConfigureDeviceSubscription/components/ReactivateDevices.vue";
import SetAutoPayment from "./ConfigureDeviceSubscription/components/SetAutoPayment.vue";
import ExtendSubscription from "./ConfigureDeviceSubscription/components/ExtendSubscription.vue";
import { getEnvironment } from "@library/helpers/environment";
import { OwnerProxyHelpers } from '@/library/helpers';

let platform = require("platform");

export default {
  mixins: [validators],
  name: 'OwnerConfigureSubscription',
  components:  {
    DeviceTable,
    ReactivateDevices,
    SetAutoPayment,
    ExtendSubscription
  },
  data() {
    return {      
      menu: null, 
      feedbackSent: false,
      loadingCombinedPlugs: false,
      addTime: null,
      selectedCurrency: null, 
      error: false,
      backEndVersion: "",
      selectedDevices: [],
      inputs: {
        options: null
      },
      dataplans: []
    };
  },
  computed: {
    ...mapState({
        loading: (state) => state.loading,
        role: (state) => state.Auth.role,
        managerview: (state) => state.Admin.managerview,
        plugs: state => state.plugs
    }),
    ...mapGetters(["plugs"]),
    ...mapGetters("Wallet", {
      specificWallets: "specificWallets",
    }),
    platformInfo() {
      return platform && platform.description ? platform.description : "No Info";
    },
    frontendVersion() {
      return this.$store.state.version;
    },
    allowConfigure() {
      return OwnerProxyHelpers.isHaveAccessToFeature("SERVICE_SUBSCRIPTION_CONFIGURE");
    }
  },
  watch: {
    menu(newMenu) {
      if (newMenu === null && this.menu !== 'select') { 
        this.selectedDevices = []
      }
      if (this.menu === 'reactivate') { 
      Api.version()
        .then(data => {
          this.backEndVersion = data;
        })
        .catch(error => {
          console.error('API Error:', error);
          this.backEndVersion = "Could not retrieve backend version";
        });
      }
    },
  },
  methods: {
    // Main Button 
    goBack() {
      switch (this.menu) {
        case null: 
          return this.$router.push('/owner/service-subscription');
        case "extend-subscription":
        case "reactivate":
        case "set-auto-payment":
          this.menu = null;
        break;
        case "select":
          switch (this.inputs.options) {
            case "Extend Subscription":
              this.menu = "extend-subscription"
            break;
            case "Reactivate":
              this.menu = "reactivate"
            break;
            case "Set Auto Payment":
              this.menu = "set-auto-payment"
            break;
          }
        // Reactivate Menu Go Back   
        case "reactivate-currency-input": 
          this.menu = "reactivate"
        break;
        case "reactivate-time-input": 
          this.menu = "reactivate-currency-input"
        break;
      }
    },
    async goNext() {      
      switch (this.menu) {
        case null: 
          switch (this.inputs.options) {
            case "Extend Subscription":
              this.menu = "extend-subscription"
            break;
            case "Reactivate":
              this.menu = "reactivate"
            break;
            case "Set Auto Payment":
              this.menu = "set-auto-payment"       
            break;
          }
        break;
        // Call API DataPlan for Extend Subscriptions */
        case "extend-subscription":
          this.loadingCombinedPlugs = true; 
          this.dataplans = [];

          const promises = this.selectedDevices.map(async (plugIdentifier) => {
            try {
              const dataplan = await Api.plugDataplanConfig("owner", { plugIdentifier });
              this.dataplans.push(dataplan);
            } catch (error) {
              console.error("Error loading Dataplan:", error);
            }
          });

          Promise.all(promises)
            .then(() => {
              this.loadingCombinedPlugs = false;
              this.menu = "extend-subscription-form";
            })
            .catch((error) => {
              console.error("Error loading Dataplans:", error);
              this.loadingCombinedPlugs = false; 
            });
        break;
          
        case "extend-subscription-form":
          this.menu = 'extend-subscription-summary'
        break; 
      
        // Reactivate Menu to Select Currency and Time Input
        case "reactivate": 
            this.menu = "reactivate-currency-input";
        break;
        case "reactivate-currency-input": 
            this.menu = "reactivate-time-input"
        break;
        
        // Call API DataPlan for Set Auto Payment */
        case "set-auto-payment":
          this.loadingCombinedPlugs = true; 
          this.dataplans = [];
          (async () => {
            for (let i = 0; i < this.selectedDevices.length; i++) {
              const plugIdentifier = this.selectedDevices[i];
              try {
                const dataplan = await Api.plugDataplanConfig("owner", { plugIdentifier });
                this.dataplans.push(dataplan);
              } catch (error) {
                console.error("Error loading Dataplan:", error);
              }
            }

            this.loadingCombinedPlugs = false;
            this.menu = "set-auto-payment-wallet";
          })();
        break;
        
        case "set-auto-payment-wallet": 
            this.menu = "set-auto-payment-confirmation"
        break; 
      }
    },
    // Reactivate Back Button 
    backToReactivate() { 
      switch (this.menu) { 
        case "reactivate-currency-input": 
          this.menu = 'reactivate'
        break; 
        case "reactivate-time-input": 
          this.menu = 'reactivate-currency-input'
        break;
      }
    },
    // Reactivate Next Step on Dialog
    goToNextStepReactivate() { 
      switch (this.menu) { 
        case "reactivate-currency-input": 
          this.menu = 'reactivate-time-input'
        break; 
      }
    },
    // Call API for Submit Reactivate */ 
    submitReactivate() {
      if (this.selectedCurrency !== null && this.addTime !== null) {
        switch (this.menu) { 
          case "reactivate-time-input": 
            this.menu = 'reactivate-submit'
          break; 
        }
        
        let title = "Reactivate Disabled Device(s)";
        let subjecTitle = `${getEnvironment()}${(this.$route.path).replaceAll("/owner/", "/").replaceAll("/admin/", "/")}`;
        let date = this.$moment().format("DD-MMM-YYYY hh:mm:ss A");
        let environment = this.platformInfo;
        let email = localStorage.getItem("email");
        let devices = this.selectedDevices; 
        let backEnd = this.backEndVersion;
        let feedback = {
          subject: `${subjecTitle} - ${email} - ${title}`,
          message: `Date: ${date}\nEnvironment: ${environment}\nBack End Version: ${backEnd} \nFront End Version: ${this.frontendVersion}
          \n\nList of device IDs:\n ${devices} \n\nCurrency: ${this.selectedCurrency}\n\nAdd on subscription time (months): ${this.addTime}`
        };
        Api.sendFeedback(feedback)
          .then(() => {
            this.feedbackSent = true;
          })
          .catch((error) => {
            this.error = error.response.data;
          })
      } else {
        this.$store.dispatch("snackbar", { message: "Please select a currency and add subscription time before submitting." });
      }
    },
    // Back to Dashboard for Reactivate
    endReactivate() { 
      return this.$router.push('/owner/service-subscription');
    },
    //Extend Subscription on dialog 
    extendToNextStep() { 
      switch (this.menu) { 
        case "extend-subscription-form": 
          this.menu = 'extend-subscription-summary'
        break;
      }
    },
    // Call API for Submit Extend Susbcription */
    async extendConfirmation(e) {
      try {
        const { inputs, total } = e;
        const requests = []; 
              
        for (let i = 0; i < this.selectedDevices.length; i++) {
          const plugIdentifier = this.selectedDevices[i];
          const dataplan = this.dataplans.find(plan => plan.external_identifier === plugIdentifier)
          if (!dataplan) continue;

          const plug = this.plugs.find(p => p.identifier === plugIdentifier)
          if (!plug) continue;

          const wallet = this.specificWallets.find(w => w.id === inputs.wallet)
          if (!wallet) continue;

          const chargeAmount = (wallet.currency === "CAD" ? dataplan.cost_cad : dataplan.cost_usd) * inputs.months;

          const payload = {
            walletId: inputs.wallet,
            chargeAmount,
            plugId: plug.id
          };
          requests.push(Api.ownerPlugRenewService(payload)); 
        }

        await Promise.all(requests);

        this.$store.dispatch("snackbar", { message: "Plugs service renew successful" });
        this.$router.push('/owner/service-subscription');
      } catch (error) {
        console.error(error)
      }
    },
    // Back Button Menu on Extend Subscription Dialog
    handleExtendBackButton() { 
      switch (this.menu) {
        case "extend-subscription-form":
          this.menu = 'extend-subscription'
        break;
      }
    },
    // Cancel button on Extend Subscription Dialog 
    handleExtendCancelButton() {
      switch (this.menu) { 
        case "extend-subscription-summary": 
          this.menu = 'extend-subscription-form'
        break;
      }
    },
    // Next Button on Set Auto Payment Dialog
    handleNextAutoDialog() { 
      switch (this.menu) { 
        case "set-auto-payment-wallet": 
          this.menu = 'set-auto-payment-confirmation'
        break;  
      }
    },
    // Back Button on Set Auto Payment Second Step Dialog
    handleBackAutoDialog() { 
      switch (this.menu) { 
        case "set-auto-payment-confirmation": 
          this.menu = 'set-auto-payment-wallet'
        break;  
      }
    },
    // Back Button on Set Auto Payment First Step Dialog
    handleCancelAutoDialog() { 
      switch (this.menu) { 
        case "set-auto-payment-wallet": 
          this.menu = 'set-auto-payment'
        break; 
      }
    },
    // Call API for Submit Auto Payment */
    async autoPaymentConfirmation(e) {
      try {
        const { inputs } = e;
        const requests = [];

        for (let i = 0; i < this.selectedDevices.length; i++) {
          const plugIdentifier = this.selectedDevices[i];
          const plug = this.plugs.find(p => p.identifier === plugIdentifier);

          if (!plug) continue;

          const wallet = this.specificWallets.find(w => w.id === inputs.autoPaymentWallet);

          const payload = {
            walletId: inputs.autoPaymentDataDisabled ? null : wallet.id,
            plugId: plug.id,
          };
          requests.push(Api.plugSetPaymentWallet("owner", payload));
        }

        await Promise.all(requests);
        this.$store.dispatch("snackbar", { message: "Plugs payment wallet update" });

        this.$router.push('/owner/service-subscription');
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.$store.dispatch("getPlugs");
    this.$store.dispatch("updateWalletBalance");
  }
};
</script>

<style> 
.device-table-component { 
  height: 100%;
}
.reactivate-card { 
  height: max-content;
  width: 300px;
  justify-content: center;
  align-items: center;
}
</style> 