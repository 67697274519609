<template>
  <v-container fluid class="fill-height text-xs-center full-inner-height">
    <v-layout align-center justify-center>
      <v-flex xs12 v-if="!!loading">
        <v-progress-circular class="loading-circle center-margin" size="50" indeterminate color="orange" />
      </v-flex>
      <v-flex xs12 lg6 md9 v-else>
        <DeviceCreationLoadBalancing
          v-if="loadBalancingDialog"
          @do-this-later="redirect"
        >
          <v-btn
            class="plugzio-button mt-4 text-transform-none"
            block
            outline
            round
            @click="redirect('owner-load-balancing-form')"
          >
            Load Balance My Device
          </v-btn>
        </DeviceCreationLoadBalancing>
        <v-card v-else>
          <v-stepper v-model="step" class="elevation-0 pb-0 mb-0">
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">Public Profile</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 2" step="2">Wallet</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 3" step="3">Payment Plan</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 4" step="4">Device</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <h3 class="hidden-md-and-up mb-4">Public Profile</h3>
                <v-form ref="stepPublicProfileForm" @submit="processStep()">
                  <v-text-field
                    outline
                    label="* Manager Name / Corporation"
                    v-model="publicProfile.name"
                    :error="!!error"
                    :disabled="!!loading || !hasAccess"
                    :rules="[rules.required]"
                    required
                    :maxlength="50"
                  />
                  <p class="text-xs-left subheading">Contact Information:</p>
                  <v-text-field
                    :maxlength="50"
                    outline
                    label="* Public E-mail"
                    v-model="publicProfile.email"
                    :rules="[rules.required, publicProfile.email ? rules.email : () => true]"
                    :error="!!error"
                    :disabled="!!loading || !hasAccess"
                  />
                  <v-text-field
                    outline
                    label="Public Phone Number"
                    v-model="publicProfile.phone"
                    :error="!!error" 
                    :disabled="!!loading || !hasAccess"
                    :maxlength="30"
                  />
                  <v-textarea
                    v-model="publicProfile.message"
                    outline
                    :error="!!error"
                    :disabled="!!loading || !hasAccess"
                    label="Message"
                    counter="1000"
                    rows="10"
                    :maxlength="1000"
                    auto-grow
                  />
                  <p class="text-xs-justify mb-4">
                    Configure information that you would like to display to Plugzio users. This information will be seen everytime a user attemps to activate your Plugzio device
                  </p>
                </v-form>
              </v-stepper-content>
              <v-stepper-content step="2">
                <h3 class="hidden-md-and-up mb-4">Wallet</h3>
                <v-form ref="stepWalletForm" @submit="processStep()">
                  <CurrencyInput
                    class="mb-3"
                    v-model="wallet.country"
                    label="Currency"
                    ref="currencyInput"
                    :rules="[rules.required]"
                    :error="!!error"
                    :disabled="!!loading"
                    outline
                    required
                  />
                  <v-text-field
                    class="mb-0"
                    v-model="wallet.description"
                    @keyup.stop.enter="processStep()"
                    label="Wallet Name"
                    outline
                    autocomplete="false"
                    required
                    ref="walletDescription"
                    :rules="[rules.required]"
                    :error="!!error"
                    :disabled="!!loading"
                  />
                </v-form>
                <v-divider class="mt-3" />
                <p class="mt-4 text-xs-left">Wallets allow you to receive and store payments from users.</p>
              </v-stepper-content>
              <v-stepper-content step="3">
                <h3 class="hidden-md-and-up mb-4">Payment Plan</h3>
                <v-form ref="stepPlanForm" @submit="processStep()">
                  <v-text-field
                    class="mb-3"
                    v-model="accessPlan.params.description"
                    label="Description"
                    outline
                    autocomplete="false"
                    required
                    hint="Name or description of the payment plan"
                    persistent-hint
                    ref="accessPlanName"
                    :rules="[rules.required]"
                    :error="!!error"
                    :disabled="!!loading"
                    autofocus
                  />
                  <v-text-field
                    class="mb-3"
                    v-model="accessPlan.params.startSessionCost"
                    :label="'Activation Fee (' + wallet.currency + '/Session)'"
                    outline
                    autocomplete="false"
                    required
                    type="number"
                    hint="Cost to start a session"
                    persistent-hint
                    :rules="[rules.allowZero]"
                    :error="!!error"
                    :disabled="!!loading"
                    @keypress="costFilter"
                    @blur="formatDecimal('startSessionCost')"
                    step="0.01"
                    min="0"
                    @keyup.enter="processStep()"
                  />
                  <v-text-field
                    class="mb-3"
                    v-model="accessPlan.params.hRate"
                    :label="'Duration Rate (' + wallet.currency + '/h)'"
                    outline
                    autocomplete="false"
                    required
                    type="number"
                    hint="Cost to use device per hour"
                    persistent-hint
                    :rules="[rules.allowZero]"
                    :error="!!error"
                    :disabled="!!loading"
                    @keypress="costFilter"
                    @blur="formatDecimal('hRate')"
                    step="0.01"
                    min="0"
                    @keyup.enter="processStep()"
                  />
                  <v-text-field
                    class="mb-2"
                    v-model="accessPlan.params.whRate"
                    :label="'Consumption Rate (' + wallet.currency + '/kWh)'"
                    outline
                    autocomplete="false"
                    required
                    type="number"
                    hint="Cost per kilowatt per hour"
                    persistent-hint
                    :rules="[rules.allowZero]"
                    :error="!!error"
                    :disabled="!!loading"
                    @keypress="costFilter"
                    @blur="formatDecimal('whRate')"
                    step="0.01"
                    min="0"
                    @keyup.enter="processStep()"
                  />
                  <v-checkbox v-if="accessPlan.params.whRate > 0" v-model="accessPlan.agree" required color="success" hide-details :rules="[(v) => !!v || '']">
                    <template v-slot:label>
                      <small>I have made sure it is legal to charge and re-sell electricity in my jurisdiction</small>
                    </template>
                  </v-checkbox>
                </v-form>
                <v-divider class="mt-3" />
                <p class="mt-4 mb-4 text-xs-justify">
                  Payment plans allow you to set billing rates for different users for different devices. Anytime you grant access to an device, users must be put on a payment plan.
                </p>
                <p class="modal-description text-xs-left">Examples:</p>
                <ul class="ma-0 mt-2 ml-3 pa-0 modal-description">
                  <li class="mb-2 text-xs-left">
                    Multiple users can be on a single payment plan for devices. These users will be charged identically when using these devices.
                  </li>
                  <li class="mb-2 text-xs-left">
                    A single user can have different payment plans for different devices. This user will be charged depending on the plan assigned for that device
                  </li>
                </ul>
                <p class="mt-2 modal-description text-xs-left">Note: If the device is public, this will be the default payment plan</p>
              </v-stepper-content>
              <v-stepper-content step="4">
                <h3 class="hidden-md-and-up mb-4">Device</h3>
                <v-form ref="stepOutletForm" @submit="processStep()">
                  <MergedRegisterPlugOnlineInput
                    :plug="plug"
                    :mode="modeChange"
                    :autopayment="{ disabled: true, walletId: null }"
                    :error="error"
                    :show-autopayment="false"
                    :use-access-plans="false"
                    @plugInputted="onPlugInputted"
                    @modeInputted="onModeInputted"
                    @error="onErrorInput"
                  />
                  <div v-if="showAutoSubscribe">
                    <v-layout align-center>
                      <h3 class="d-inline-block">Service Subscription Plan:</h3>
                      <v-icon
                        class="tooltip-icon mb-0 ml-2"
                        v-tooltip="{
                          content: `Plugzio devices typically come with 1 year of cloud dashboard access. 
                                    <br/>After a year, you have the option of subscribing to a monthly to keep your device connected to the cloud. 
                                    <br/>Subscription settings can always be changed in the under the &quot;Service Subscription&quot; page.`,
                          trigger: `click hover`,
                        }"
                      >
                        info
                      </v-icon>
                    </v-layout>
                    <v-autocomplete
                      v-model="autoSubscribe"
                      :items="[{ value: false, text: 'Disabled' }, { value: true, text: 'Enabled' }]"
                      item-text="text"
                      item-value="value"
                      label="Wallet"
                      outline
                      autocomplete="false"
                      required
                      :rules="[(v) => [true, false].includes(v) || 'This field is required']"
                      :error="!!error"
                      class="mt-3 wallet-picker"
                    />
                    <v-layout v-if="!autoSubscribe" align-center>
                      <p class="wallet-hint mb-0 text-xs-left">
                        Note: We strongly recommend enabling this option so you will not have to worry about your device's cloud subscription in the future. This can always be
                        changed under the "Service Subscription" page
                      </p>
                    </v-layout>
                  </div>
                </v-form>
              </v-stepper-content>
              <v-stepper-content step="5" class="py-3 px-3 text-xs-left">
                <h3 class="mb-3 ml-2 mt-2" v-if="!loading && !done && selectedRegistrationMethod != 'offline'">Summary</h3>
                <v-layout row wrap v-if="loading">
                  <v-flex xs12 class="pa-2 py-4 text-xs-center">
                    <strong>Processing:</strong>
                    {{ loading }}
                    <br />
                    <v-progress-circular class="mt-4" size="40" indeterminate color="orange" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap v-else-if="done && !error && selectedRegistrationMethod == 'offline'">
                  <v-flex xs12 class="pa-2 py-4 text-xs-center">
                    <h3 v-if="!error" class="text-xs-center">Setup is completed</h3>
                    <p class="mt-3 pb-4 mb-4 flex text-xs-justify">
                      Offline devices will start in "Always On" mode. Once the device is online, you can change this mode in the device management page.
                    </p>
                    <!-- TODO: delete this button because it changed with load balancing dialog -->
                    <!-- <v-btn 
                      class="plugzio-button mb-2 mt-4 wifi-setup-button" 
                      block 
                      outline 
                      round 
                      @click="goToDeviceManagement"
                    >
                      Go To Device Management 
                    </v-btn> -->
                  </v-flex>
                </v-layout>
                <v-layout row wrap v-else>
                  <v-flex xs12 class="pa-2 py-4 text-xs-center" v-if="error && done">
                    <strong>Could not complete setup:</strong>
                    <br />
                    <span class="error--text">{{ error }}</span>
                  </v-flex>
                  <template v-else-if="selectedRegistrationMethod == 'online'">
                    <v-flex xs12 class="pa-2">
                      <strong>Public Profile</strong>
                      <v-divider class="mt-2 mb-3"></v-divider>
                      <div class="modal-description mt-2">
                        <p>
                          Manager:
                          <span class="black--text">{{ publicProfile.name }}</span>
                        </p>
                        <p v-if="!!publicProfile.email">
                          E-mail:
                          <span class="black--text">{{ publicProfile.email }}</span>
                        </p>
                        <p v-if="!!publicProfile.phone">
                          Phone #:
                          <span class="black--text">{{ publicProfile.phone }}</span>
                        </p>
                        <p>
                          Message:
                          <span class="black--text">{{ publicProfile.message }}</span>
                        </p>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="pa-2">
                      <strong>Wallet</strong>
                      <v-divider class="mt-2 mb-3"></v-divider>
                      <div class="modal-description mt-2">
                        <p>
                          Name:
                          <span class="black--text">{{ wallet.description }}</span>
                        </p>
                        <p>
                          Currency:
                          <span class="black--text">{{ wallet.currency }}</span>
                        </p>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="pa-2">
                      <strong>Payment Plan</strong>
                      <v-divider class="mt-2 mb-3"></v-divider>
                      <div class="modal-description mt-2">
                        <p>
                          Description:
                          <span class="black--text">{{ accessPlan.params.description }}</span>
                        </p>
                        <p>
                          Activation Fee:
                          <span class="black--text">{{ formatCost(accessPlan.params.startSessionCost, wallet.currency) }}</span>
                        </p>
                        <p>
                          Duration Rate:
                          <span class="black--text">{{ formatRate(accessPlan.params.hRate, "h", wallet.currency) }}</span>
                        </p>
                        <p>
                          Consumption Rate:
                          <span class="black--text">{{ formatRate(accessPlan.params.whRate, "kWh", wallet.currency) }}</span>
                        </p>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="pa-2">
                      <strong>Device</strong>
                      <v-divider class="mt-2 mb-3"></v-divider>
                      <div class="modal-description mt-2">
                        <p>
                          ID:
                          <span class="black--text">{{ plug.plugIdentifier }}</span>
                        </p>
                        <p>
                          Description:
                          <span class="black--text">{{ plug.description }}</span>
                        </p>
                        <p>
                          Mode:
                          <span class="black--text">Smart</span>
                        </p>
                        <p>
                          Access:
                          <span class="black--text">{{ modeChange.ispublic ? "Public" : "Private" }}</span>
                        </p>
                        <p v-if="['CAD', 'USD'].includes(wallet.currency)">
                          Auto Subscribe:
                          <span class="black--text">{{ autoSubscribe ? "Enabled" : "Disabled" }}</span>
                        </p>
                      </div>
                    </v-flex>
                  </template>
                  <v-flex v-else class="pa-4">
                    <v-form ref="stepRegisterPlugOfflineInputForm" @submit="submit()">
                      <MergedRegisterPlugOfflineInput
                        :plug="plug"
                        :mode="modeChange"
                        :error="error"
                        :use-access-plans="false"
                        @plugInputted="onPlugInputted"
                        @modeInputted="onModeInputted"
                        @error="onErrorInput"
                        @qrScanned="submit()"
                      />
                    </v-form>
                  </v-flex>
                </v-layout>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <v-divider class="my-2" />
          <v-card-actions class="pt-0" v-if="!loading">
            <v-btn v-if="!done" color="gray" flat="flat" @click="step === 1 ? ($router.push('/owner')) : previousStep()">
              {{ step === 1 ? "Back To Dashboard" : "Previous" }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!done"
              color="green darken-1"
              flat="flat"
              :loading="!!loading"
              :disabled="!hasAccess"
              @click.stop.prevent="step > 4 ? submit() : processStep()"
            >
              NEXT
            </v-btn>
            <v-btn v-else-if="!error" color="green darken-1" flat="flat" @click.stop.prevent="showLoadBalancingDialog">NEXT</v-btn>
            <v-btn v-else color="green darken-1" flat="flat" @click.stop.prevent="reset()">TRY AGAIN</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <ExitConfirmationModal 
      :show="leaving.show_confirmation"
      :text='`You are currently in the middle of the "Get Started" process. Navigating away from this page will cause you to lose any changes you have mode.<br/>Please go back and complete the process if you would like to keep your changes.`'
      @confirmed="leaving.confirmed = true;$router.push(leaving.to);"
      @cancel="leaving.confirmed = false;leaving.to = null;leaving.show_confirmation = false;"
      @changed="(v) => leaving.show_confirmation = v"
    />
  </v-container>
</template>

<script>
import validators from "@/mixins/validators";
import helpers from "@/mixins/helpers";
import plugModes from "@/mixins/plugModes";
import { mapState, mapGetters } from 'vuex';
import Api from "@library/apis/Api";
import ExitConfirmationModal from '@/components/modals/ExitConfirmationModal.vue';
import CurrencyInput from "@/components/inputs/CurrencyInput.vue";
import MergedRegisterPlugOnlineInput from "@/components/mergedRegisterPlug/MergedRegisterPlugOnlineInput.vue";
import MergedRegisterPlugOfflineInput from "@/components/mergedRegisterPlug/MergedRegisterPlugOfflineInput.vue";
import DeviceCreationLoadBalancing from '@/components/loadBalancing/DeviceCreationLoadBalancing.vue';
import { OwnerProxyHelpers } from '@/library/helpers';

export default {
  name: 'GetStarted',
  mixins: [validators, helpers, plugModes],
  components: {
    MergedRegisterPlugOnlineInput,
    MergedRegisterPlugOfflineInput,
    ExitConfirmationModal,
    CurrencyInput,
    DeviceCreationLoadBalancing
  },
  data() {
    return {
      selectedRegistrationMethod: "online",
      plug: {
        id: null,
        plugIdentifier: null,
        description: null,
        ispublic: null,
        offlineRegistrationToken: null,
        hideDevice: false
      },
      modeChange: {
        ispublic: null,
        plan_id: null,
      },
      accessPlan: {
        id: null,
        params: {
          description: null,
          planType: "simple-plan",
          startSessionCost: null,
          walletId: null,
          whRate: null,
          hRate: null,
        },
        agree: false,
      },
      wallet: {
        id: null,
        country: null,
        currency: null,
        description: null,
        params: {
          paypalEmail: "",
          gateway: "paypal",
        },
      },
      publicProfile: {
        name: null,
        email: null,
        phone: null,
        message: null,
      },
      step: 1,
      error: null,
      timeout: 0,
      done: false,
      autoSubscribe: false,
      leaving: {
        confirmed: false,
        show_confirmation: false,
        to: null,
      },
      loadBalancingDialog: false,
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      adminActiveView: (state) => state.Admin.activeView,
      managerview: (state) => state.Admin.managerview,
      userview: (state) => state.Admin.userview,
      loading: (state) => state.loading,
      deviceRegistrationSecretToken: (state) => state.deviceRegistrationSecretToken
    }),
    ...mapGetters("Admin", {
      adminViewAsUser: "viewAsUser",
    }),
    showAutoSubscribe() {
      return ["USD", "CAD"].includes(this.wallet.currency);
    },
    hasAccess() {
      return OwnerProxyHelpers.isHaveAccessToFeature("GET_STARTED")
    }
  },
  watch: {
    "wallet.country"() {
      if (this.wallet.country)
        this.wallet.currency = this.wallet.country.toString().substr(-4).replace(")", "");
    },
    "wallet.currency": function(value) {
      this.autoSubscribe = false;
      if (["USD", "CAD"].includes(value)) this.autoSubscribe = true;
    },
  },
  methods: {
    processStep() {
      if (this.step > 4) return;
      if (this.step === 1 && this.$refs.stepPublicProfileForm.validate()) this.step++;
      else if (this.step === 2 && this.$refs.stepWalletForm.validate()) this.step++;
      else if (this.step === 3 && this.$refs.stepPlanForm.validate()) this.step++;
      else if (this.step === 4 && this.$refs.stepOutletForm.validate()) this.step++;
    },
    previousStep() {
      if (this.selectedRegistrationMethod == "offline") {
        this.selectedRegistrationMethod = "online";
      } else if (this.step > 0) this.step--;
    },
    costFilter($event) {
      let cost = String($event.target.value);
      if (cost.indexOf(".") > -1 && cost.split(".")[1].length > 1) $event.preventDefault();
    },
    formatDecimal(param) {
      if (param) {
        this.accessPlan.params[param] = parseFloat(this.accessPlan.params[param]).toFixed(2);
      } else {
        this.accessPlan.params[param] = 0.0;
      }
    },
    submit() {
      /*
        steps:
          0) register public profile
          1) register device
          2) create wallet
          3) get wallet id
          4) create plan
          5) get plan id
          6) set mode
          7) finish - redirect to dashboard
			*/

      let apiPrefix = this.role; 
      if (this.role === "admin") {
        if (!!this.managerview) {
          this.wallet.ownerUsername = this.managerview.owner_username
          apiPrefix = "admin/owner"
        }
        if (!!this.userview) {
          this.wallet.username = this. adminViewAsUser.username
          apiPrefix = "admin/user"
        }
      }

      if (this.selectedRegistrationMethod == "offline" && !this.$refs.stepRegisterPlugOfflineInputForm.validate()) return;

      let plug = JSON.parse(JSON.stringify(this.plug));
      let modeChange = JSON.parse(JSON.stringify(this.modeChange));
      if (this.selectedRegistrationMethod == "online") {
        plug.offlineRegistrationToken = null;
      } else {
        plug.ispublic = null;
        modeChange = {
          ispublic: null,
          plan_id: null,
        };
      }

      this.timeout ? this.$store.dispatch("loading", "Registering Public Profile...") : this.$store.dispatch("loading", "Setting up account...");

      Api.ownerSetPublicProfile({ publicProfile: this.publicProfile })
        .then(() => {
          setTimeout(() => {
            registerPlug();
          }, this.timeout);
        })
        .catch((error) => {
          errorHandler(error, "Could not register new device.");
        });

      let registerPlug = () => {
        if (this.timeout) this.$store.dispatch("loading", "Registering device...");

        Api.plugRegister(this.role, plug)
          .then(() => {
            setTimeout(() => {
              createWallet();
            }, this.timeout);
          })
          .catch((error) => {
            const message = error ? (error.response ? error.response.data : "") : "";
            console.error(message);
            if (["device not reachable", "plug not reachable"].includes(message.toLowerCase())) {
              this.selectedRegistrationMethod = "offline";
              this.$store.dispatch("loading", false);
              if (this.deviceRegistrationSecretToken) {
                this.plug.offlineRegistrationToken = this.deviceRegistrationSecretToken;
                this.$nextTick(() => this.submit());
              }
            } else errorHandler(error, "Could not register new device.");
          });
      };

      let createWallet = () => {  
        Api.walletsCreate(apiPrefix, this.wallet)
          .then(() => {
            setTimeout(() => {
              getWallets();
            }, this.timeout);
          })
          .catch((error) => {
            errorHandler(error, "Could not create wallet.");
          });
      };

      let getWallets = () => {        
        Api.wallets(apiPrefix)
          .then((data) => {
            this.wallet.id = data[0].id;
            setTimeout(() => {
              createPlan();
            }, this.timeout);
          })
          .catch((error) => {
            errorHandler(error, "Could not get new wallet.");
          });
      };

      let createPlan = () => {
        if (this.timeout) this.$store.dispatch("loading", "Creating payment plan...");
        let data = {
          planId: this.accessPlan.id,
          params: {
            description: this.accessPlan.params.description,
            planType: "simple-plan",
            startSessionCost: parseFloat(this.accessPlan.params.startSessionCost || 0),
            walletId: this.wallet.id,
            hRate: parseFloat(this.accessPlan.params.hRate || 0),
            whRate: parseFloat(this.accessPlan.params.whRate ? this.accessPlan.params.whRate / 1000 : 0),
          },
        };
        if (this.role === 'admin' && !!this.managerview) data.ownerUsername = this.managerview.owner_username; 
        Api.plugPaymentAccessPlansCreate(this.role, data)
          .then(() => {
            setTimeout(() => {
              getPlan();
            }, this.timeout);
          })
          .catch((error) => {
            errorHandler(error, "Could not create new plan.");
          });
      };

      let getPlan = () => {
        Api.plugPaymentAccessPlans(apiPrefix)
          .then((data) => {
            this.accessPlan.id = data[0].id;
            setTimeout(() => {
              outletAccess();
            }, this.timeout);
          })
          .catch((error) => {
            errorHandler(error, "Could not get new payment plan.");
          });
      };

      let outletAccess = () => {
        if (this.timeout) this.$store.dispatch("loading", "Setting device mode...");
        const data = modeChange.ispublic ? `{"plugIdentifier":"${plug.plugIdentifier}","planId":"${this.accessPlan.id}"}` : `{"plugIdentifier":"${plug.plugIdentifier}"}`;
        Api.plugSetAccess("owner", modeChange.ispublic ? "public" : "private", data)
          .then(() => {
            setTimeout(() => {
              if (this.autoSubscribe) autoSubscribe();
              else this.finishProcess();
            }, this.timeout);
          })
          .catch((error) => {
            errorHandler(error, "Could not set device access mode.");
          });
      };

      let autoSubscribe = () => {
        if (this.timeout) this.$store.dispatch("loading", "Setting Auto Subscribe...");
        Api.plugSetPaymentWallet("owner", { plugIdentifier: plug.plugIdentifier, walletId: this.wallet.id })
          .then(() => {
            setTimeout(() => {
              this.finishProcess();
            }, this.timeout);
          })
          .catch((error) => {
            errorHandler(error, "Could not set device access mode.");
          });
      };

      let errorHandler = (error, message) => {
        console.log(error);
        this.$store.dispatch("loading", false);
        error.response.status === 400 ? (this.error = error.response.data) : (this.error = message);
        this.done = true;
        this.leaving.confirmed = true;
      };
    },
    finishProcess() {
      this.$store.dispatch("loading", "Finalizing please wait...");
      setTimeout(() => {
        this.done = true;
        this.leaving.confirmed = true;
        this.$store.dispatch("loading", false);
        if (!this.error) {
          if (this.selectedRegistrationMethod == "online") this.showLoadBalancingDialog();
        }
      }, 200);
    },
    goToDeviceManagement() {
      this.$router.push({ name: 'owner-outlet-management' });
      this.$store.dispatch("loading", false);
      this.$nextTick(() => {
        window.location.reload();
      });
    },
    showLoadBalancingDialog() {
      this.loadBalancingDialog = true
    },
    redirect(route = "owner-dashboard") {
      this.leaving.confirmed = true;
      this.$router.push({ name: route });
      this.$store.dispatch("loading", false);
      this.$nextTick(() => {
        window.location.reload();
      });
    },
    reset() {
      this.step = 5;
      this.error = false;
      this.done = false;
    },
    onPlugInputted(value) {
      this.plug = value;
    },
    onModeInputted(value) {
      this.modeChange = value;
    },
    onErrorInput(value) {
      this.error = value;
    },
  },
  mounted() {
    this.wallet.params.paypalEmail = localStorage["email"];
    this.step = 1;
    this.selectedRegistrationMethod = "online";
  },
  beforeRouteLeave: function(to, from, next) {
    if (this.leaving.confirmed) return next();
    
    this.leaving.to = to.path;
    this.leaving.show_confirmation = true;
    this.leaving.confirmed = false;
    next(false);
  },
}
</script>

<style lang="scss">
.v-stepper__step__step.primary {
	background: #0d920d !important
}

.modal-description p {
	margin-bottom: .4em
}
</style>
